import React, { createContext, PropsWithChildren, useEffect } from 'react';

import { RequestStatus } from '../../../../../types/store';
import { ControllerProps } from '../..';

export const WidgetContext = createContext<ControllerProps>(null!);

type WidgetContextProviderProps = PropsWithChildren<ControllerProps>;

export const WidgetContextProvider: React.FC<WidgetContextProviderProps> = (props) => {
  useEffect(() => {
    const { redeemCouponStatus } = props.couponsConfig;
    if (redeemCouponStatus === RequestStatus.SUCCESS || redeemCouponStatus === RequestStatus.FAILURE) {
      props.couponsHandlers.resetRedeemCouponStatus();
    }
  }, [props.couponsConfig.redeemCouponStatus]);

  useEffect(() => {
    const { fetchAccountStatus } = props.accountConfig;
    if (fetchAccountStatus === RequestStatus.SUCCESS || fetchAccountStatus === RequestStatus.FAILURE) {
      props.accountHandlers.resetFetchAccountStatus();
    }
  }, [props.accountConfig.fetchAccountStatus]);

  return <WidgetContext.Provider value={props}>{props.children}</WidgetContext.Provider>;
};

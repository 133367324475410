import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import React, { useEffect } from 'react';
import { Toast as TPAToast, ToastPlacement } from 'wix-ui-tpa/cssVars';

import { getToastContent } from '../../../utils/get-toast-content';
import { useToast, useToastActions } from './hooks/context-hooks';
import { classes, st } from './toast.st.css';

export const Toast: React.FC = () => {
  const { isMobile } = useEnvironment();
  const { hideToast } = useToastActions();
  const { isVisible, toastType } = useToast();
  const { t } = useTranslation();
  const { toastContent, toastSkin } = getToastContent(toastType, t);

  useEffect(() => {
    if (isVisible) {
      const timeout = setTimeout(hideToast, 5000);
      return () => clearTimeout(timeout);
    }
  }, [toastType]);

  return (
    <TPAToast
      skin={toastSkin}
      children={toastContent}
      onClose={() => hideToast()}
      className={st(classes.toast, {
        mobile: isMobile,
      })}
      shouldShowCloseButton
      isShown={isVisible}
      placement={isMobile ? ToastPlacement.bottomFullWidth : ToastPlacement.inline}
    />
  );
};

import { noop } from 'lodash';
import React, { PropsWithChildren, useState } from 'react';

import { CouponCodeModal, CouponCodeModalType } from '../../modals/coupon-code-modal/coupon-code-modal';
import { GetCouponModal, GetCouponModalType } from '../../modals/get-coupon-modal/get-coupon-modal';

export enum ModalType {
  COUPON_CODE = 'COUPON_CODE',
  GET_COUPON = 'GET_COUPON',
}

type Modal = CouponCodeModalType | GetCouponModalType;

interface ModalContextProps {
  modal: Modal | null;
  showModal: (modal: Modal) => void;
  hideModal: () => void;
}

interface ModalComponentProps {
  modal: Modal;
  hideModal: () => void;
}

const initialValues: ModalContextProps = {
  modal: null,
  showModal: noop,
  hideModal: noop,
};

const ModalComponent: React.FC<ModalComponentProps> = ({ modal, hideModal }) => {
  switch (modal.type) {
    case ModalType.COUPON_CODE: {
      return <CouponCodeModal {...modal.props} onClose={hideModal} />;
    }
    case ModalType.GET_COUPON: {
      return <GetCouponModal {...modal.props} onClose={hideModal} />;
    }
    default: {
      return null;
    }
  }
};

export const ModalContext = React.createContext<ModalContextProps>(initialValues);

type ModalProviderProps = PropsWithChildren;

export const ModalProvider: React.FC<ModalProviderProps> = (props) => {
  const [modal, setModal] = useState<Modal | null>(null);

  const showModal = (m: Modal) => {
    setModal(m);
  };

  const hideModal = () => {
    setModal(null);
  };

  return (
    <ModalContext.Provider value={{ modal, showModal, hideModal }}>
      {props.children}
      {modal && <ModalComponent modal={modal} hideModal={hideModal} />}
    </ModalContext.Provider>
  );
};

import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../types/data-hook';
import { Divider } from './divider';
import { classes } from './error-state.st.css';

export const ErrorState = () => {
  const { t } = useTranslation();
  return (
    <div data-hook={DataHook.ErrorState} className={classes.root}>
      <Divider />
      <div className={classes.content}>
        <Text tagName="h3" data-hook={DataHook.ErrorStateTitle} className={classes.title}>
          {t('app.error-state.service-not-available')}
        </Text>
        <Text tagName="p" data-hook={DataHook.ErrorStateDescription} className={classes.description}>
          {t('app.error-state.reload-page')}
        </Text>
      </div>
      <Divider />
    </div>
  );
};

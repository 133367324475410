import { useLocale } from './context-hooks';

export const useNumberFormatter = () => {
  const { locale } = useLocale();

  const formatNumber = (value: number) => {
    return new Intl.NumberFormat(locale).format(value);
  };

  return { formatNumber };
};

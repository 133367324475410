import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

export type ISettingsParams = {
  pageTitle: SettingsParamType.Text;
  pageDescription: SettingsParamType.Text;
  button: SettingsParamType.Text;
  getButton: SettingsParamType.Text;
  copyButton: SettingsParamType.Text;
  continueButton: SettingsParamType.Text;
};

export default createSettingsParams<ISettingsParams>({
  pageTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.pageTitle'),
  },
  pageDescription: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.pageDescription'),
  },
  button: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.button'),
  },
  getButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.button-get'),
  },
  copyButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.button-copy'),
  },
  continueButton: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.settings.defaults.button-continue'),
  },
});

import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../types/data-hook';
import { Divider } from './divider';
import { classes } from './empty-state.st.css';

export const EmptyState = () => {
  const { t } = useTranslation();
  return (
    <div data-hook={DataHook.EmptyState} className={classes.root}>
      <Divider />
      <div className={classes.content}>
        <Text data-hook={DataHook.EmptyStateTitle} tagName="h2" className={classes.title}>
          {t('app.empty-state.no-pending-rewards')}
        </Text>
        <Text data-hook={DataHook.EmptyStateDescription} tagName="p" className={classes.subtitle}>
          {t('app.empty-state.nothing-to-see-here')}
        </Text>
      </div>
      <Divider />
    </div>
  );
};

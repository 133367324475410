import { Tier } from '@wix/ambassador-loyalty-v1-tier/types';

export const getNextTierId = (
  tiers: Tier[],
  currentTierId: string | undefined | null,
): { nextTierId: string | undefined | null } => {
  if (!tiers.length) {
    return { nextTierId: undefined };
  }

  const copyOfTiers = [...tiers];
  const nextTierIndex = copyOfTiers
    .sort((tierA, tierB) => tierA.requiredPoints! - tierB.requiredPoints!)
    .findIndex((tier) => tier.requiredPoints! > tiers.find(({ id }) => id === currentTierId)?.requiredPoints!);

  if (!currentTierId) {
    return { nextTierId: copyOfTiers[0].id };
  }

  if (nextTierIndex === -1) {
    return { nextTierId: undefined };
  }

  return { nextTierId: copyOfTiers[nextTierIndex].id };
};

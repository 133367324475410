import { RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { loyaltyUouClick } from '@wix/bi-logger-loyalty-uou/v2';
import { useStyles } from '@wix/tpa-settings/react';
import { CheckSmall as CheckSmallIcon } from '@wix/wix-ui-icons-common/on-stage';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import React, { useState } from 'react';
import { Badge, BadgePriority, Divider, Text } from 'wix-ui-tpa/cssVars';

import { DataHook } from '../../../types/data-hook';
import { SimpleReward } from '../../../types/domain';
import { isRewardAvailable } from '../../../utils';
import { RewardsLayout } from '../stylesParams';
import { ModalType } from './hocs/modal-context';
import { useAccount, useAppInstallStatus, useCouponsActions, useModal, useRewardsActions } from './hooks';
import { classes } from './list-item.st.css';
import { ListItemButton } from './list-item-button';

interface ListItemProps {
  reward: SimpleReward;
  isLastReward: boolean;
}

export const ListItem: React.FC<ListItemProps> = ({ reward, isLastReward }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { showModal } = useModal();
  const { isOldRestaurantsAppInstalled, isReferralsAppInstalled, isLoyaltyAppInstalled } = useAppInstallStatus();
  const { redeemCoupon } = useCouponsActions();
  const { applyReward } = useRewardsActions();
  const { account } = useAccount();
  const [copied, setCopied] = useState(false);
  const { isMobile } = useEnvironment();
  const bi = useBi();
  const showDivider =
    !isLastReward &&
    styles
      .getStylesForAllBreakpoints()
      .some((breakpointStyles) => breakpointStyles.numbers.rewardsLayout === RewardsLayout.List);
  const showBadgeAboveTitle =
    styles
      .getStylesForAllBreakpoints()
      .some((breakpointStyles) => breakpointStyles.numbers.rewardsLayout === RewardsLayout.Cards) || isMobile;
  const showBadgeBehindTitle = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.numbers.rewardsLayout === RewardsLayout.List);

  const isOnlyReferralsAppInstalled = isReferralsAppInstalled && !isLoyaltyAppInstalled;
  const isCheckoutDiscountReward =
    [RewardType.DISCOUNT_AMOUNT, RewardType.SPI_DISCOUNT_AMOUNT].includes(reward.type!) &&
    !isOldRestaurantsAppInstalled;

  const pointsBalance = account.pointsBalance ?? 0;
  const requiredPoints = reward.requiredPoints ?? 0;
  const hasEnoughPoints = isRewardAvailable({ pointsBalance, requiredPoints });

  const handleRewardButtonClick = () => {
    // 'Continue shopping' action
    if (isCheckoutDiscountReward) {
      bi.report(
        loyaltyUouClick({
          value: 'continue_shopping',
          origin: 'my_rewards_page',
        }),
      );
      applyReward(reward);
      return;
    }

    // 'Copy code' action
    if (reward.couponActivated) {
      navigator.clipboard.writeText(reward.couponCode!);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 5000);
      bi.report(
        loyaltyUouClick({
          value: 'copy_coupon_code',
          origin: 'my_rewards_page',
        }),
      );
      return;
    }

    // 'Get coupon' action
    if (reward.type === RewardType.COUPON_REWARD) {
      bi.report(
        loyaltyUouClick({
          value: 'redeem_coupon_reward',
          origin: 'my_rewards_page',
        }),
      );
      showModal({
        type: ModalType.GET_COUPON,
        props: {
          reward,
          onConfirm: () => {
            bi.report(
              loyaltyUouClick({
                value: 'redeem_coupon_reward',
                origin: 'popup',
              }),
            );
            redeemCoupon(reward);
          },
        },
      });
      return;
    }

    // Old restaurants 'Apply reward' action
    bi.report(
      loyaltyUouClick({
        value: 'redeem_reward',
        origin: 'my_rewards_page',
      }),
    );
    applyReward(reward);
  };

  const useSecondaryBadgeForReferrals = !isOnlyReferralsAppInstalled;

  const badge = reward.isReferralReward ? (
    <Badge
      className={useSecondaryBadgeForReferrals ? classes.secondaryBadge : classes.primaryBadge}
      priority={useSecondaryBadgeForReferrals ? BadgePriority.light : BadgePriority.default}
    >
      {t('app.reward-description.referral-program')}
    </Badge>
  ) : isCheckoutDiscountReward && hasEnoughPoints ? (
    <Badge className={classes.primaryBadge} icon={<CheckSmallIcon />}>
      {t('app.reward-description.available-in-checkout')}
    </Badge>
  ) : null;

  return (
    <>
      <div data-hook={DataHook.RewardsListItem} className={classes.item}>
        <div className={classes.contentContainer}>
          <div>
            {showBadgeAboveTitle && badge && <div className={classes.badgeAboveTitle}>{badge}</div>}
            <Text tagName="h3" data-hook={DataHook.RewardsListItemTitle} className={classes.title}>
              {reward.title}
              {showBadgeBehindTitle && badge && <div className={classes.badgeBehindTitle}>{badge}</div>}
            </Text>
          </div>
          <Text tagName="p" data-hook={DataHook.RewardsListItemDescription} className={classes.description}>
            {reward.description}
          </Text>
        </div>
        <div className={classes.buttonContainer}>
          <ListItemButton
            showCopied={copied}
            reward={reward}
            onClick={handleRewardButtonClick}
            isCheckoutDiscountReward={isCheckoutDiscountReward}
            hasEnoughPoints={hasEnoughPoints}
          />
        </div>
      </div>
      {showDivider && <Divider className={classes.divider} />}
    </>
  );
};

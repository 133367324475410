import { TFunction } from '@wix/yoshi-flow-editor';
import { ToastSkin } from 'wix-ui-tpa/cssVars';

import { ToastType } from '../../types/toast';

export const getToastContent = (toastType: ToastType, t: TFunction) => {
  let toastContent: string | null;
  let toastSkin: ToastSkin;

  switch (toastType) {
    case ToastType.ERROR_GENERAL: {
      toastContent = t('app.toast.error-general');
      toastSkin = ToastSkin.error;
      break;
    }
    case ToastType.ERROR_UNAVAILABLE_COUPON: {
      toastContent = t('app.toast.error-unavailable-coupon');
      toastSkin = ToastSkin.error;
      break;
    }
    default: {
      toastContent = null;
      toastSkin = ToastSkin.success;
    }
  }

  return {
    toastContent,
    toastSkin,
  };
};
